<template lang="pug">
gmap-autocomplete(@place_changed="setPlace")
  template(v-slot:input="slotProps")
    v-text-field(
      :placeholder="placeholder"
      :label="label"
      ref="input"
      v-model="search"
      v-on:listeners="slotProps.listeners"
      v-on:attrs="slotProps.attrs")
      v-btn(
        icon
        slot="append"
        x-small
        v-if="search"
        @click="clearPlace()")
        v-icon mdi-close
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'GoogleMapAutocomplete',
  props: {
    params: {
      type: Array,
      default: x => []
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      search: null,
      result: null,
      paramsArr: [
        {
          key: 'log',
          gfield: 'route'
        },
        {
          key: 'code',
          gfield: 'postal_code'
        },
        {
          key: 'neighborhood',
          gfield: 'sublocality'
        },
        {
          key: 'country',
          gfield: 'country'
        },
        {
          key: 'region',
          gfield: 'administrative_area_level_1'
        },
        {
          key: 'city',
          gfield: 'administrative_area_level_2'
        },
        {
          key: 'number',
          gfield: 'street_number'
        }
      ]
    }
  },
  created () {
    if (this.params.length) {
      this.paramsArr = cloneDeep(this.params)
    }
  },
  watch: {
    result () {
      this.$emit('input', this.result)
    }
  },
  methods: {
    clearPlace () {
      this.result = null
      this.search = null
    },
    setPlace({ address_components }) {
      // The params can be received as prop to more custom results
      this.result = this.searchParams(this.paramsArr, address_components)
    },
    searchParams (params, arr) {
      try {
        const obj = {}
        for(const i in params) {
          const result = arr.filter( v => v.types.includes(params[i].gfield))
          if (result.length)
            obj[params[i].key] = result[0].long_name || result[0].short_name
        }
        return obj
      } catch (error) {
        // vazio
      }
      return null
    }
  }
}
</script>
